export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const NAMEPREFIX = [
  { label: "MR.", value: "MR." },
  { label: "MRS.", value: "MRS." },
  { label: "MS.", value: "MS." },
];

export const SECTORTYPE = [
  { label: "DOMESTIC", value: "DOMESTIC" },
  { label: "INTERNATIONAL", value: "INTERNATIONAL" },
];

export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const TRAVELTYPE = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
];

export const TRAVELTYPEALL = [
  { label: "TOUR", value: 1 },
  { label: "PACKAGE", value: 2 },
  { label: "ALL", value: 3 },
];

export const JOURNEYTYPE = [
  { label: "DEPARTURE", value: "DEPARTURE" },
  { label: "RETURN", value: "RETURN" },
];

export const RATETYPE = [
  { label: "PER PERSON", value: "PER PERSON" },
  { label: "PER COUPLE", value: "PER COUPLE" },
];

export const CURRENCY = [
  { label: "INR", value: 1 },
  { label: "USD", value: 2 },
];

export const VEHICLESHARINGPAX = [
  { label: "2 PAX", value: 1 },
  { label: "3 PAX", value: 2 },
  { label: "4 PAX", value: 3 },
  { label: "5 PAX", value: 4 },
  { label: "SHARING", value: 5 },
];

export const VEHICLESHARING = [
  { label: "SHARING", value: "SHARING" },
  { label: "SEPARATE", value: "SEPARATE" },
];

export const STARRATING = [
  { label: "1 STAR", value: 1 },
  { label: "2 STAR", value: 2 },
  { label: "3 STAR", value: 3 },
  { label: "4 STAR", value: 4 },
  { label: "5 STAR", value: 5 },
];
export const PAYMENTFOR = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
  { label: "TICKET", value: "TICKET" },
];

export const BOOLEAN = [
  { label: "Yes", value: 1 },
  { label: "NO", value: 2 },
];

export const PAYMENTTYPE = [
  { label: "CASH", value: "CASH" },
  { label: "CREDIT CARD", value: "CREDIT CARD" },
  { label: "CHEQUE", value: "CHEQUE" },
  { label: "NEFT / IMPS", value: "NEFT / IMPS" },
  { label: "UPI", value: "UPI" },
  { label: "BANK DEPOSIT", value: "BANK DEPOSIT" },
  // { label: "CREDIT NOTE", value: "CREDIT NOTE" },
];

export const UPITYPE = [
  { label: "Paytm", value: "PAYTM" },
  { label: "PhonePay", value: "PHONEPAY" },
  { label: "GooglePay", value: "GOOGLEPAY" },
];

export const ONWARDJOURNEYTYPE = [
  { label: "ONE WAY", value: "ONE WAY" },
  { label: "TWO WAY", value: "TWO WAY" },
  { label: "MULTI WAY", value: "MULTI WAY" },
];

export const DATETYPE = [
  { label: "Booking Date", value: 1 },
  { label: "Travel Date", value: 2 },
];

export const CUSTOMERREPORTDATETYPE = [
  { label: "Booking Date", value: 1 },
  { label: "Travel Date", value: 2 },
  { label: "Tour Date", value: 3 },
];

export type ISelectionType = {
  value: number | string;
  label: string;
};

export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;

export const INSERT_ACCESS = "1";
export const EDIT_ACCESS = "2";
export const DELETE_ACCESS = "3";
export const PDF_DOWNLOAD_ACCESS = "4";
export const EXCEL_EXPORT_ACCESS = "5";
export const PRINT_ACCESS = "6";
