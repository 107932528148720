import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getTourBookingInvoice } from "../services/reports.services";
import { MdCurrencyRupee } from "react-icons/md";
import Logo from "../../../assets/Images/logo.png";
// import RKQRCode from "../../../assets/Images/rkqrcode.jpg";

const TourBookingInvoiceView: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const TourBookingNo = params?.TourBookingNo;
  const TourBookingInvoice = useAppSelector(
    (state) => state.reports.TourBookingInvoice
  );

  useEffect(() => {
    if (TourBookingNo) {
      dispatch(getTourBookingInvoice(TourBookingNo));
    }
  }, [TourBookingNo]);

  return (
    <>
      <div className="container-fluid p-5" style={{ fontSize: 12 }}>
        <div className="row">
          <div className="col-2">
            <img src={Logo} alt="" width="100%" />
          </div>
          <div className="col-7" style={{ borderLeft: "1px solid black" }}>
            <h5>{TourBookingInvoice?.CompanyName}</h5>
            <p>{TourBookingInvoice?.CompanyAddress}</p>
            <div className="d-flex" style={{ gap: 100 }}>
              <span>
                {TourBookingInvoice?.CompanyGSTNo && "(GST) "}
                {TourBookingInvoice?.CompanyGSTNo} <br />
                {TourBookingInvoice?.CompanyMobileNO && "(P) "}
                {TourBookingInvoice?.CompanyMobileNO} <br />
                {TourBookingInvoice?.CompanyEmailID && "(Email) "}
                {TourBookingInvoice?.CompanyEmailID}
              </span>
            </div>
            {/* <div>(PAN) AAHCR8625E</div> */}
          </div>
          <div className="col-3 text-end">
            {/* <p>{TourBookingInvoice?.CompanyEmailID}</p> */}
            {/* <div>
              <p className="fw-600 m-0">Scan here to pay</p>
              <img src={RKQRCode} alt="" width={100} />
            </div> */}
          </div>
        </div>
        <div
          className="row mt-2 pt-2 pb-2"
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <div className="col-6 p-2 ps-0">
            To, <br />
            <span style={{ fontSize: 12, fontWeight: 700 }}>
              {TourBookingInvoice?.CustomerName}
            </span>
            <br />
            <span>
              {TourBookingInvoice?.MobileNo} <br />{" "}
              {TourBookingInvoice?.EmailID}
            </span>
            <br />
            <span>
              {TourBookingInvoice?.Address}
              {TourBookingInvoice?.CityName && ", "}
              {TourBookingInvoice?.CityName}
              {TourBookingInvoice?.StateName && ", "}
              {TourBookingInvoice?.StateName}
            </span>
          </div>
          <div
            className="col-6 p-3"
            //  style={{ background: "lightgray" }}
          >
            {/* <p className="text-center">Domestic Air Ticket Invoice</p>
            <div className="d-flex justify-content-between">
              <span>RHJ12124521</span>
              <span>{TourBookingInvoice?.BookingDate}</span>
            </div>
            <span>Airline PNR (CTYGVH45)</span> */}
          </div>
        </div>
        {/* <div className="row pt-2">
          <div className="col-12 ps-0">
            <p>Airline : Indigo Corporate CTAMD036</p>
          </div>
        </div> */}
        <div className="row pt-2">
          <div
            className="col-3 p-2 fw-600"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Passenger
          </div>
          <div
            className="col-2 p-2 fw-600"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Sector
          </div>
          <div
            className="col-2 p-2 fw-600"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Flight
          </div>
          <div
            className="col-2 p-2 fw-600"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Travel On
          </div>
          {/* <div
            className="col-1 p-2"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Airline PNR
          </div> */}
          <div
            className="col-1 p-2 fw-600"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Basic
          </div>
          <div
            className="col-1 p-2 fw-600"
            style={{ background: "lightgray", borderRight: "1px solid white" }}
          >
            Other
          </div>
          <div className="col-1 p-2 fw-600" style={{ background: "lightgray" }}>
            Total
          </div>
        </div>
        <div className="row">
          <div className="col-3 p-2"> {TourBookingInvoice?.CustomerName}</div>
          <div className="col-2 p-2">
            {" "}
            {TourBookingInvoice?.SectorName}
            {TourBookingInvoice?.TourCode && " - "}
            {TourBookingInvoice?.TourCode}
          </div>
          <div className="col-2 p-2">
            {TourBookingInvoice?.PickUpFlightNo || "-"}
          </div>
          <div className="col-2 p-2"> {TourBookingInvoice?.TourStartDate}</div>
          {/* <div className="col-1 p-2">PNR</div> */}
          <div className="col-1 p-2">{TourBookingInvoice?.TotalCost}</div>
          <div className="col-1 p-2">{TourBookingInvoice?.TotalExtraCost}</div>
          <div className="col-1 p-2">{TourBookingInvoice?.TotalCost}</div>
        </div>
        <div className="row" style={{ margin: "0 0 50px 0" }}>
          <div className="col-3 p-2"> </div>
          <div className="col-2 p-2"> </div>
          <div className="col-2 p-2">
            {TourBookingInvoice?.DropFlightNo || "-"}
          </div>
          <div className="col-1 p-2"> </div>
          <div className="col-1 p-2"></div>
          <div className="col-1 p-2"></div>
          <div className="col-1 p-2"></div>
          <div className="col-1 p-2"></div>
        </div>
        <div className="row mt-5">
          <p>
            <span className="fw-bold">
              {TourBookingInvoice?.Remarks && `Remarks: `}
            </span>
            {TourBookingInvoice?.Remarks}
          </p>
        </div>
        <div
          className="row"
          style={{
            borderTop: "1px solid black",
            // borderBottom: "1px solid black",
          }}
        >
          <div className="col-8 p-2 ps-0">
            {/* <span className="fw-600">
              UPI/5545454564564564654/5645646165465561/
              {TourBookingInvoice?.CustomerName}
            </span> */}
          </div>
          <div className="col-4 p-0 pe-2">
            <div className="row">
              <div className="col-7">Grass Total</div>
              <div className="col-5 text-end">
                {TourBookingInvoice?.TotalCost}
              </div>
              <div className="col-7">Vehicle Cost</div>
              <div className="col-5 text-end">
                {TourBookingInvoice?.VehicleCost}
              </div>
              <div className="col-7">Less Discount Amount</div>
              <div className="col-5 text-end">
                {TourBookingInvoice?.TotalDiscountAmount}
              </div>
              <div className="col-7">Add TCS</div>
              <div className="col-5 text-end">
                {TourBookingInvoice?.TotalTCSAmount}
              </div>
              <div className="col-7">Add GST</div>
              <div className="col-5 text-end">
                {TourBookingInvoice?.TotalGSTAmount}
              </div>
            </div>
          </div>
        </div>
        <div className="row border border-dark p-2">
          <div className="col-8 ps-0"></div>
          <div className="col-4 ps-3 pe-1">
            <div className="row">
              <div className="col-7 p-0 d-flex align-items-center fw-600">
                Total Amount in <MdCurrencyRupee />
              </div>
              <div className="col-5 p-0 pe-2 text-end fw-600">
                {(TourBookingInvoice?.Total || 0) +
                  (TourBookingInvoice?.VehicleCost
                    ? +TourBookingInvoice?.VehicleCost
                    : 0)}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-7 col-md-8 p-0">
            <p className="fw-600">Terms :</p>
            <p>
              ◙ Subject to Ahmedabad jurisdiction. <br />
              ◙ Subject to realisation of Cheque. <br />
              ◙ Without original receipt no refund s permissible. <br />◙ Kindly
              check all details carefully to avoid unneccessary complications.
            </p>
          </div>
          <div className="col-5 col-md-4  p-0 text-start fw-bold d-flex align-items-end">
            <p>
              <span style={{ fontSize: 15 }}>Our Bank Details:-</span> <br />
              ICICI BANK ACCOUNT NO: 002405500555 <br /> NAME OF ACCOUNT : MOJ
              TOURISM <br /> IFSC CODE : ICIC0000024 <br /> BRANCH : JMC HOUSE
            </p>
          </div>
        </div>
        <div
          className="row"
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <div className="col-8"></div>
          <div className="col-4 p-0 text-end">
            <span className="fw-600">for MOJ TOURISM</span>
          </div>
          <div className="col-4" style={{ padding: "100px 0 0 0" }}>
            Receiver's Signature
          </div>
          <div className="col-4"></div>
          <div className="col-4 text-end" style={{ padding: "100px 0 0 0" }}>
            Authorized Signature
          </div>
        </div>
        <div className="row ">
          <div className="col-3 p-0">
            (Prepared By : {TourBookingInvoice?.BookBy})
          </div>
          <div className="col-6 text-center" style={{ fontSize: 12 }}>
            This is a Computer generated document and does not require any
            signature
          </div>
          <div className="col-3 p-0 text-end"></div>
        </div>
      </div>
    </>
  );
};

export default TourBookingInvoiceView;
