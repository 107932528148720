import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllActiveTours } from "../../tour/services/tour.services";
import { ITour } from "../../tour/tourModel";
import { clearTourReportList } from "../reportSlice";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { clearTourDateList } from "../../hotelblock/hotelBlockSlice";
import { getTourDates } from "../../hotelblock/services/hotelBlock.services";
import { ITourDateList } from "../../hotelblock/hotelBlockModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const TourChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = React.useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");

  useEffect(() => {
    if (TourID) {
      dispatch(getTourDates(TourID));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourDate", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [TourID]);

  return null;
};

const TourReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const TourList = useAppSelector((state) => state.tour.TourList);
  const TourDateList = useAppSelector((state) => state.hotelBlock.TourDateList);

  useEffect(() => {
    dispatch(getAllActiveTours());

    return () => {
      dispatch(clearTourReportList());
      dispatch(clearTourDateList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload: any = {
      TourID: values?.TourID ? values?.TourID : null,
    };
    if (values?.TourDate) {
      (payload.TourDate = values?.TourDate ? values?.TourDate : "0"),
        (payload.FromDate = "0"),
        (payload.ToDate = "0");
    } else {
      (payload.FromDate = values?.FromDate
        ? moment(values?.FromDate).format("YYYY-MM-DD")
        : "0"),
        (payload.ToDate = values?.ToDate
          ? moment(values?.ToDate).format("YYYY-MM-DD")
          : "0"),
        (payload.TourDate = "0");
    }
    window.open(
      `tourreportview/${payload?.TourID}/${payload?.TourDate}/${payload?.FromDate}/${payload?.ToDate}`
    );
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ cols: 10, rows: 0 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Tour Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourID"
                      label="Tour"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={TourList?.map((tour: ITour) => {
                        return {
                          value: tour?.ID,
                          label: tour?.TourName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name={"TourDate"}
                      label={"Tour Date"}
                      component={FormSelectionField}
                      options={TourDateList?.map((tourDates: ITourDateList) => {
                        return {
                          value: `${moment(
                            tourDates?.TourDate,
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")}`,
                          label: tourDates?.TourDate,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      name="FromDate"
                      label="From Date"
                      format="dd/MM/yyyy"
                      disabled={
                        formRenderProps.valueGetter("TourDate") ? true : false
                      }
                      wrapperStyle={{ width: "100%" }}
                      component={FormDatePicker}
                    />
                    <Field
                      name="ToDate"
                      label="To Date"
                      format="dd/MM/yyyy"
                      disabled={
                        formRenderProps.valueGetter("TourDate") ? true : false
                      }
                      wrapperStyle={{ width: "100%" }}
                      component={FormDatePicker}
                      validator={
                        formRenderProps.valueGetter("FromDate") &&
                        requiredValidator
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                      minHeight: 90,
                    }}
                  >
                    <div style={{ position: "absolute", top: 42, left: 0 }}>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <TourChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default TourReportForm;
