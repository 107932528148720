import React from "react";
import "./login.scss";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import FormPasswordField from "../../components/formFields/FormPasswordField";
import {
  passwordValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import Logo from "../../assets/Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { employeeLogin } from "./loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

const LogIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.login.loading);

  const handleSubmit = async (values: any) => {
    const data = {
      UserName: values.UserName,
      Password: values.Password,
    };
    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/");
    }
  };
  return (
    <div className="main-container">
      <div className="firstpart">
        <div className="info">
          <h1>WELCOME TO THE PORTAL MOJ TOURISM</h1>
        </div>
      </div>
      <div className="middlepart"></div>
      <div className="lastpart">
        <div className="subpart">
          <img src={Logo} alt="" className="logo" />
          <div className="login-box">
            <h2>Login</h2>
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                UserName: "",
                Password: "",
                RememberMe: false,
              }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <Field
                    name={"UserName"}
                    type={"text"}
                    component={FormTextField}
                    label={"User Name"}
                    validator={requiredValidator}
                  />
                  <Field
                    name={"Password"}
                    type={"password"}
                    component={FormPasswordField}
                    label={"Password"}
                    validator={passwordValidator}
                  />
                  <div className="forgot">
                    <Field
                      wrapperClassName={"remember-me"}
                      id={"RememberMe"}
                      name={"RememberMe"}
                      label={"Remember Me"}
                      component={FormCheckbox}
                    />
                    <Link to="/">Forgot password</Link>
                  </div>
                  <ButtonWithLoading
                    label="Login"
                    themeColor="primary"
                    type="submit"
                    width="100%"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <div className="register">
                    <p>
                      Not registered yet? <br />
                      <Link to="/signup">Register Now</Link>
                    </p>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
